import './vendors/doubleTapToGo';
import './components/anchor-block';
import './components/custom.select';
import IMC from './components/IMC';
import initCarousels from './carousels';
import { textareaResize, fileUpload } from './helpers';
import FlashMessage from './components/flash-message';
import Swiper, { Navigation, Pagination } from './vendors/swiper';

(function ($) {

    IMC.init();
    initCarousels();
    textareaResize();
    fileUpload();
    FlashMessage.initialPosition = 550;


    $('.anchor-block').anchorBlock({
        linksContainer: '.anchor-block__links',
        contentContainer: '.anchor-block__content',
        activeLinkClass: 'is-active'
    });


    // Popup Fermeture Cabinet
    // function _hasCookies(cookieName){
    //     if(document.cookie.indexOf(cookieName+"=true") > -1){
    //         return true;
    //     } else if(document.cookie.indexOf(cookieName+"=false") > -1){
    //         return false;
    //     }
    //     return null;
    // }
    // function _setCookies(name, value, minutes) {
    //     var d = new Date();
    //     d.setTime(d.getTime() + (minutes * 60 * 1000));
    //     var expires = "expires="+d.toUTCString();
    //     document.cookie = name + "=" + value + ";" + expires + ";path=/";
    // }

    // if (_hasCookies('PopupCabinetClosed') == null) {
    //   setTimeout(function () {
    //     $('.popup_closeinfos, .popup_closeinfos__overlay').fadeIn(400);
    //   }, 1000);
    // }

    // $(document).on('click', '.popup_closeinfos__close', function(event) {
    //   $('.popup_closeinfos, .popup_closeinfos__overlay').fadeOut('400');
    //   _setCookies("PopupCabinetClosed",true,60);
    //   event.preventDefault();
    // });


    /**
     * Sub menu header
     */
    $(function () {

        $('.header__menu .menu-item-has-children')
            .on('mouseenter touchstart', function () {
                let $subMenu = $(this).find('.sub-menu').first();

                $subMenu.css({
                    'visibility': 'hidden',
                    'display': 'block'
                });

                let offset = $subMenu.offset(),
                    left = offset.left,
                    width = $subMenu.width(),
                    documentWidth = $('body').width(),
                    isEntirelyVisible = (left + width <= documentWidth);

                $subMenu.css({
                    'visibility': '',
                    'display': ''
                });

                if (!isEntirelyVisible) $(this).addClass('sub-menu-edge');

                $(this).addClass('is-active');
                $subMenu.fadeIn();
            });

            $('.header__menu .menu-item-has-children').on('mouseleave touchend', function () {
                let $subMenu = $(this).find('.sub-menu').first();

                $subMenu.css({
                    'visibility': '',
                    'display': ''
                });

                $(this).removeClass('is-active sub-menu-edge');
                $subMenu.fadeOut();
            });

        /**
         * If touch responsive, sub menu show on first click, if another click,
         * go to the page
         */
        $('.header__menu .menu-item-has-children').doubleTapToGo({
            'menu': true
        });

    });

    /**
     * Responsive menu
     */
    $(function () {
        let $menu = $('.responsive-menu'),
            $menuOverlay = $('.responsive-menu__overlay'),
            $hamburger = $('.header__hamburger');

        if ($menu.length) {

            /**
             * On click on the hamburger, enable menu or disable
             */
            $hamburger.on('click', function () {
                let $hamburgerIcon = $(this).find('div').first();

                if ($hamburgerIcon.hasClass('is-active')) {
                    $hamburgerIcon.removeClass('is-active');
                    $menu.slideUp();
                    $menuOverlay.fadeOut();
                } else {
                    $(this).find('div').first().addClass('is-active');
                    $menu.slideDown();
                    $menuOverlay.fadeIn();
                }
            });

            /**
             * On click on the overlay or on the hamburger activated, disable menu
             */
            $menuOverlay.on('click', function () {
               $hamburger.find('div').first().removeClass('is-active');
               $menu.slideUp();
               $menuOverlay.fadeOut();
            });

            /**
             * Responsive menu sub menus
             */
            $(function () {
                $menu.find('li.menu-item-has-children a').on('click', function (e) {
                    e.preventDefault();

                    $(this).parent().toggleClass('is-active');
                    $(this).parent().find('.sub-menu').first().slideToggle();
                });

                $menu.find('li.menu-item-has-children a > span').on('click', function (e) {
                    e.stopPropagation();
                });
            });
        }
    });

    /**
     * Remove attribut
     */
    $(function(){
        // Vérifier si des éléments avec la classe 'vc_general' existent
        if ($('.vc_general').length > 0) {
            // Supprimer l'attribut 'title' de tous les éléments avec la classe 'vc_general'
            $('.vc_general').removeAttr('title');
        } 
    });
    /**
     * Remove VC tabs animations
     */
    $(function(){
        if (typeof $.fn.vcAccordion !== "undefined") $.fn.vcAccordion.Constructor.prototype.isAnimated = () => { return 0; }
    });

    /**
     * Youtube video play iframe
     */
    $(function () {
        let $videoEmbed = $('.video-embed'),
            $videoEmbedPlay = $videoEmbed.find('.video-embed__play');

        if ($videoEmbed.length && $videoEmbedPlay.length) {
            $videoEmbedPlay.on('click', function () {
               let $videoEmbed = $(this).parent().parent(),
                   $videoEmbedText = $(this).parent(),
                   $videoIframe = $(this).parent().parent().find('iframe'),
                   videoEmbedIframeSrc = $videoIframe.attr('src');

               $videoIframe.attr('src', videoEmbedIframeSrc + '?autoplay=1');
               setTimeout(function () {
                   $videoEmbed.addClass('is-active');
                   $videoEmbedText.fadeOut();
               }, 150);
            });
        }
    });

    /**
     * Buttons container same width / height
     */
    $(function () {
        let $buttonsContainer = $('.buttons-container');

        if ($buttonsContainer.length) {
            $(window).on('load resize', function () {
                $buttonsContainer.each(function () {
                    let $buttons = $(this).find('.btn-secondary').find('p'),
                        maxWidth, maxHeight;

                    $buttons.each(function () {
                        $(this).find('a').removeAttr('style');
                    });

                    maxWidth = Math.max.apply(Math, $buttons.map(function () {
                        return $(this).outerWidth();
                    }).get());

                    maxHeight = Math.max.apply(Math, $buttons.map(function () {
                        return $(this).outerHeight();
                    }).get());

                    $buttons.each(function () {
                        $(this).find('a').css({
                            'width': maxWidth,
                            'height': maxHeight
                        });
                    });
                });
            });
        }
    });

    /**
     * Tabs with map
     */
    $(function () {
        let $tabsMap = $('.tabs-with-map');

        if ($tabsMap.length) {
            $tabsMap.find('.vc_tta-tab').on('click', function () {
                if (!$(this).hasClass('vc_active')) {
                    let index = $(this).index();
                    $tabsMap.find('.tabs-with-map__map .is-active').removeClass('is-active');
                    $tabsMap.find(`.tabs-with-map__map > div > div > div > div:eq(${index})`).addClass('is-active');
                }
            });
        }
    });

    /**
     * Search form
     */
    $(function () {
       let $searchButton = $('.header__search'),
           $searchClose = $('.search-form__close'),
           $searchOverlay = $('.search-form__overlay'),
           $searchForm = $('.search-form');

       $searchButton.on('click', function (e) {
           e.preventDefault();
           $('body').addClass('search-form-active');
           $searchOverlay.fadeIn();
           $searchForm
               .css('display', 'flex')
               .hide()
               .fadeIn();
           $searchForm.find('input').focus();
       });

       $searchClose.on('click', function (e) {
           e.preventDefault();
           $('body').removeClass('search-form-active');
           $searchOverlay.fadeOut();
           $searchForm.fadeOut();
       });
    });

    /**
     * Back to top
     */
    $(function () {
        let $backToTop = $('.back-to-top');

        $backToTop.on('click', function () {
            $('html, body').stop().animate({scrollTop: 0}, 500);
        });

        $(window).on('load scroll', function () {
            if ($backToTop.length) {
                let scrollTop = $(window).scrollTop();

                if (scrollTop > 500) $backToTop.addClass('is-active'); else $backToTop.removeClass('is-active');
            }
        });
    });

    function stickyMenu () {
        let currentScroll = $(window).scrollTop(),
            windowWidth = $(window).width(),
            $header = $('.header'),
            $body = $('body'),
            $topHeader = $('.top-header'),
            $pageWrapper = $('.page-wrapper');

        if (windowWidth >= 1025) {
            $header.removeAttr('style');
            if (currentScroll >= 0) {
                $body.addClass('header-is-sticky');
                $pageWrapper.css('margin-top', $header.outerHeight() + $topHeader.outerHeight());
            } else {
                $body.removeClass('header-is-sticky');
                $pageWrapper.removeAttr('style');
            }
        } else {
            if (currentScroll >= $topHeader.outerHeight() + $header.find('.header__logo').outerHeight()) {
                $body.addClass('header-is-sticky');
                $header.css('margin-top', ($header.find('.header__logo').outerHeight() + 10) * -1);
                $pageWrapper.css('margin-top', $header.outerHeight());
            } else {
                $body.removeClass('header-is-sticky');
                $header.removeAttr('style');
                $pageWrapper.removeAttr('style');
            }
        }
    }

    $(window).on('resize scroll load', stickyMenu);
    
    // Nouvelle section pour les fonctionnalités ajoutées
    $(function () {
    /**
     * Ajout de focus et blur sur les inputs textarea
     */
    function inputFocused() {
        var textareas = document.querySelectorAll('.input-control input');

        function handleFocus() {
            if (!this.classList.contains('input--focused')) {
                this.classList.add('input--focused');
                this.parentNode.classList.add('input--active');
            }
        }

        function handleBlur() {
            if (this.value === '' && this.classList.contains('input--focused')) {
                this.classList.remove('input--focused');
                this.parentNode.classList.remove('input--active');
            }
        }

        for (var i = 0; i < textareas.length; i++) {
            textareas[i].addEventListener('focus', handleFocus);
            textareas[i].addEventListener('blur', handleBlur);
        }
    }

    /**
     * Validation du formulaire de commentaires
     */
    function validateForm() {
        const form = document.querySelector('#comment-form');
        if (!form) return;
    
        const nameEL = document.querySelector('#author');
        const emailEL = document.querySelector('#email');
        const commentEL = document.querySelector('#comment');
    
        const isRequired = value => value.trim() !== '';
        const isEmailValid = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    
        const showError = formField => {
            formField.parentElement.classList.remove('success');
            formField.parentElement.classList.add('error');
        };
    
        const showSuccess = formField => {
            formField.parentElement.classList.remove('error');
            formField.parentElement.classList.add('success');
        };
    
        const validateField = (field, validator) => {
            const value = field.value.trim();
            if (!validator(value)) {
                showError(field);
                return false;
            } else {
                showSuccess(field);
                return true;
            }
        };
    
        const checkUsername = () => validateField(nameEL, isRequired);
        const checkEmail = () => validateField(emailEL, isEmailValid);
        const checkComment = () => validateField(commentEL, isRequired);
    
        form.setAttribute('novalidate', true);
    
        form.addEventListener('submit', event => {
            event.preventDefault();
    
            const isUsernameValid = checkUsername();
            const isEmailValid = checkEmail();
            const isCommentValid = checkComment();
    
            if (isUsernameValid && isEmailValid && isCommentValid) {
                form.submit();
            }
        });
    }

    /**
     * 
     *  sliderBeforeAfter
     * 
    */

    function sliderBeforeAfter() {
        const $thumbnailHoverBlocks = document.querySelectorAll('.sliderBeforeAfter .swiper-container');
    
        if ($thumbnailHoverBlocks.length === 0) {
            return;
        }
    
        [...$thumbnailHoverBlocks].forEach($el => {
            const swiper = new Swiper($el, {
                modules: [Navigation, Pagination],
                slidesPerView: 1, // Default value for large screens
                loop: true,
                spaceBetween: 10,
                pagination: {
                    el: $el.querySelector('.swiper-pagination'),
                    clickable: true,
                },
                navigation: {
                    nextEl: $el.querySelector('.swiper-button-next'),
                    prevEl: $el.querySelector('.swiper-button-prev'),
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                        centeredSlides: true,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 17,
                    }
                },
                on: {
                    init: function () {
                        // Vérifier si le nombre de slides correspond à slidesPerView
                        const totalSlides = $el.querySelectorAll('.swiper-slide').length;
                        const slidesPerView = this.params.slidesPerView >= 1 ? this.params.slidesPerView : 1;
                        const shouldSlide = totalSlides > slidesPerView;
    
                        // Définir l'attribut data-slide
                        $el.setAttribute('data-slide', shouldSlide ? 'true' : 'false');
                    },
                    resize: function () {
                        // Recalculer au redimensionnement
                        const totalSlides = $el.querySelectorAll('.swiper-slide').length;
                        const slidesPerView = this.params.slidesPerView >= 1 ? this.params.slidesPerView : 1;
                        const shouldSlide = totalSlides > slidesPerView;
    
                        // Mettre à jour l'attribut data-slide
                        $el.setAttribute('data-slide', shouldSlide ? 'true' : 'false');
                    },
                },
            });
        });
    }
    
    
    inputFocused();
    validateForm();
    sliderBeforeAfter();
});

})(jQuery);