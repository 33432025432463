import Swiper from './vendors/swiper';

/**
 * Initialize all carousel
 */
export default function initCarousels() {

    /* HOME CAROUSEL, OVERWEIGHT & OBESITY */
    if (jQuery('.home__overweight-obesity__slider .default-carousel').length) {
        new Swiper('.home__overweight-obesity__slider .default-carousel', {
           slidesPerView: 1.3,
           spaceBetween: 10,
           observer: true,
           observeParents: true,
           scrollbar: {
               el: '.swiper-scrollbar',
               draggable: true
           },
           breakpoints: {
               767: {
                   slidesPerView: 3,
                   spaceBetween: 20,
               }
           }
        });
    }

    /* IMAGE SLIDER */
    if (jQuery('.image-slider').length) {
        jQuery('.image-slider').each(function () {
            var imageSlider = new Swiper(jQuery(this), {
                slidesPerView: 1,
                observer: true,
                observeParents: true,
                loop: true,
                pagination: {
                    el: jQuery(this).parent().find('.image-slider__pagination'),
                    clickable: true
                },
                navigation: {
                    prevEl: jQuery(this).parent().find('.image-slider__prev'),
                    nextEl: jQuery(this).parent().find('.image-slider__next')
                }
            });
        });
    }

}