/**
 * IMC MODULE
 */

const IMC = {

    element: '.imc-module',

    /**
     * Add a click listener on each submit IMC button
     */
    init: function () {
        let elements = document.querySelectorAll(this.element);

        elements.forEach((element) => {
           element.querySelector('.imc-module__submit').addEventListener('click', () => {
               this.submit(this.element);
           });
        });
    },

    /**
     * Get inputs datas
     *
     * @param element
     */
    getDatas: function (element) {
        let el = document.querySelector(element),
            sex = (el.querySelector('.same-as-selected') !== null) ? el.querySelector('.same-as-selected').innerHTML : null,
            size = (el.querySelector('#imc-module__size').value !== null) ? el.querySelector('#imc-module__size').value : null,
            weight = (el.querySelector('#imc-module__weight').value !== null) ? el.querySelector('#imc-module__weight').value : null,
            age = (el.querySelector('#imc-module__age').value !== null) ? el.querySelector('#imc-module__age').value : null;

        return {
            sex: sex,
            size: size,
            weight: weight,
            age: age
        }
    },

    /**
     * Send AJAX request to verify inputs values
     *
     * @param element
     */
    submit: function (element) {
        var _this = this;
        _this.loading(element);

        jQuery.ajax({
            url: '/wp-content/themes/servajean/IMC.php',
            method: 'POST',
            data: this.getDatas(element),
            dataType: 'json',
            success: function (data) {
                if (!data.errors) {
                    _this.success(element, data.success);
                } else {
                    _this.error(element, data.errors);
                }
            },
            error: function (error) {
                _this.loading(element, false);
                console.log('Error during the AJAX request.', {error})
            }
        });
    },

    /**
     * Make loading
     */
    loading: function (element, isLoading = true) {
        let el = document.querySelector(element);

        if (isLoading)
            el.classList.add('is-loading');
        else
            el.classList.remove('is-loading');
    },

    /**
     * Success
     */
    success: function (element, IMC) {
        this.loading(element, false);

        jQuery(element).addClass('imc-module__have-result');
        jQuery(element).html(`<p class="imc-module__result"><strong>Votre IMC est de : </strong> ${IMC.value}</p><p class="imc-module__result">Interprétation de votre IMC selon la classification de l'OMS : <strong>${IMC.text}</strong></p>`);
        jQuery('html, body').stop().animate({
            scrollTop: jQuery(element).offset().top - 200
        });
    },

    /**
     * Handle errors
     */
    error: function (element, errors) {
        this.loading(element, false);

        let el = document.querySelector(element),
            sex = el.querySelector('.custom-select'),
            size = jQuery(el).find('#imc-module__size'),
            weight = jQuery(el).find('#imc-module__weight'),
            age = jQuery(el).find('#imc-module__age');

        for (let i in errors) {
            switch (i) {
                case 'sex':
                    sex.classList.add('imc-module__input-error');
                    break;
                case 'size':
                    size.parent().addClass('imc-module__input-error');
                    // size.parent().find('p').remove();
                    // size.parent().append(`<p>${errors[i]}</p>`);
                    break;
                case 'weight':
                    weight.parent().addClass('imc-module__input-error');
                    // weight.parent().find('p').remove();
                    // weight.parent().append(`<p>${errors[i]}</p>`);
                    break;
                case 'age':
                    age.parent().addClass('imc-module__input-error');
                    // age.parent().find('p').remove();
                    // age.parent().append(`<p>${errors[i]}</p>`);
                    break;
            }
        }

        /* RESET ERRORS INPUT ON TYPE */
        jQuery('.imc-module__input-error').find('input').on('blur', function () {
           if (jQuery(this).val() !== '') {
               jQuery(this).closest('.imc-module__input-error').removeClass('imc-module__input-error');
               // jQuery(this).parent().find('p').remove();
           }
        });
        jQuery('.imc-module__input-error').find('.select-items').on('click', function () {
            jQuery(this).closest('.imc-module__input-error').removeClass('imc-module__input-error');
        });
    }

};

export default IMC;