/**
 * Auto resize textarea (for a one line textarea)
 *
 * @param el
 */
const autosize = function(el) {
    setTimeout(function () {
        jQuery(el).css('height', 'auto');
        jQuery(el).css('height', parseInt(el.scrollHeight) + 'px');
    }, 0);
};

/**
 * Activate the resize of textarea on enter or escape key
 */
export const textareaResize = function () {
    jQuery('textarea').keydown(function (e) {
        let key = e.which || e.keyCode;
        if (key === 13 || key === 8) {
            autosize(this);
        }
    });
};

/**
 * Double click on the block, and get the hover block displayed before we change the page
 * If it's a touch device
 *
 * @param elements
 */
export const touchBlocks = (elements = []) => {
    elements.map((element) => {
        let DOMelements = document.querySelectorAll(element);

        DOMelements.forEach((DOMelement) => {
            if (typeof DOMelement !== "undefined") {
                var link = DOMelement.getElementsByTagName('a')[0],
                    cachedX, cachedY;

                if (typeof link !== "undefined") {
                    link.addEventListener('touchend', (event) => event.preventDefault());
                }

                /* Cache X and Y position to check if it's a tap or a swipe */
                DOMelement.addEventListener('touchstart', function (event) {
                    cachedX = event.pageX;
                    cachedY = event.pageY;
                });

                /* Check if current X and Y is the same as cached, then prevent click or click */
                DOMelement.addEventListener('touchend', function (event) {
                    let currX = event.pageX,
                        currY = event.pageY;

                    if (cachedX === currX && cachedY === currY) {
                        if (!this.classList.contains('is-hover')) {
                            event.preventDefault();
                            this.classList.add('is-hover');
                        } else {
                            this.classList.remove('is-hover');
                            if (typeof link !== "undefined") link.click();
                        }
                    }
                });
            }
        });
    });
};

/**
 * File upload style + script to add image
 */
export const fileUpload = function (settings = {
    'file_upload_container': jQuery('.file-upload'),
    'file_upload_text': jQuery('.file-upload__text'),
    'file_upload_input': jQuery('.file-upload__input'),
    'file_upload_default_input': jQuery('.file-upload').find('input[type="file"]')
}) {
    jQuery(function () {
        let file_upload_container     = settings.file_upload_container,
            file_upload_text          = settings.file_upload_text,
            file_upload_input         = settings.file_upload_input,
            file_upload_default_input = settings.file_upload_default_input;

        // On click on the fake input, click on the file input
        file_upload_input.on('click', function () {
            file_upload_default_input.click();
        });

        // Text of the input
        file_upload_default_input.on('change', function () {
            let text = this.files[0].name; // Default text

            if (this.files.length > 1) {
                text = `${this.files.length} fichiers`;
            }

            file_upload_text.html(text);
        });

        // Reset file upload on mail sent
        jQuery('.wpcf7').each(function () {
            jQuery(this).on('wpcf7mailsent', function (event) {
                jQuery(this).find(file_upload_input).val('');
                jQuery(this).find(file_upload_text).html('Aucun fichier choisi');
            });
        });
    });
};